import { Rule } from 'antd/lib/form';
import { RuleObject } from 'rc-field-form/lib/interface';

export const defaultUUIDFieldValidation: RuleObject[] = [
  { 
    required: true,
    type: "string",
    min: 36,
    max: 36,
    message: 'Der Wert sollte die Form 00000000-0000-0000-0000-00000000000 haben.',
    validator: (_: any, value: string) => {
      const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      if(uuidRegex.test(value)){
        return Promise.resolve();
      }else {
        return Promise.reject(new Error("Der Wert sollte die Form 00000000-0000-0000-0000-00000000000 haben."));
      }
    } 
  },
]

export const defaultStringFieldValidation = [
  { required: true, message: 'Bitte gib einen Wert an.' },
  {
    min: 3,
    max: 60,
    whitespace: true,
    message: 'Der Wert muss min. 3 und max. 60 Zeichen lang sein.'
  }
];

export const stringFieldValidation1to40 = [
  { required: true, message: 'Bitte gib einen Wert an.' },
  {
    min: 1,
    max: 40,
    whitespace: true,
    message: 'Der Wert muss min. 1 und max. 40 Zeichen lang sein.'
  }
];

export const defaultSelectFieldValidation = [
  { required: true, message: 'Bitte triff eine Auswahl.' }
];

export const numberGreaterThanZero: Rule[] = [
  {
    required: true,
    validator: (_, val) => {
      if (val <= 0) {
        return Promise.reject(new Error('Der Wert muss größer oder gleich 0 sein'));
      }
      return Promise.resolve();
    }
  },
  { type: 'number', message: 'Bitte gib eine Zahl ein' }
];

export const defaultNumberFieldLimit: Rule[] = [
  {
    type: 'number',
    min: 0,
    max: 99999,
    message: 'Nutze maximal 5 Ziffern.'
  },
  { type: 'number', message: 'Bitte gib eine Zahl ein' },
  { required: true, message: 'Bitte gib einen Wert an' }
];

export const defaultTaxFieldValidations: Rule[] = [
  {
    type: 'string',
    min: 13,
    max: 26,
    message: 'Der Wert muss min. 13 und max. 26 Zeichen lang sein.'
  },
  { required: true, message: 'Bitte gib einen Wert an.' }
];

export const defaultVATNumber: Rule[] = [
  { required: true, message: 'Bitte gib einen Wert an.' },
  {
    min: 9,
    max: 18,
    whitespace: true,
    message: 'Der Wert muss min. 9 und max. 18 Zeichen lang sein.'
  }
];

export const defaultFileFieldValidation = [
  { required: true, message: 'Bitte gib einen Dateinamen ein.' }
];

export const defaultDateValidation = [{ required: true, message: 'Bitte gib ein Datum ein.' }];

export const specialCharactersValidation: Rule[] = [
  {
    validator: (_, value) => {
      const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/;
      const spaceRegex = /\s/;

      if (specialCharactersRegex.test(value)) {
        return Promise.reject('Der Wert darf keine Sonderzeichen enthalten.');
      }
      if (spaceRegex.test(value)) {
        return Promise.reject('Leerzeichen sind nicht erlaubt.');
      }

      return Promise.resolve();
    }
  }
];

export const dateIsFuture: Rule[] = [
  {
    required: true,
    validator: (_, val) => {
      if (!val) return Promise.resolve();
      const selectedDate = val.toDate();
      const currentDate = new Date();

      if (selectedDate <= currentDate) {
        return Promise.reject(
          new Error('Bitte wähle ein Datum aus, das nach dem aktuellen Datum liegt.')
        );
      }
      return Promise.resolve();
    }
  }
];

export const defaultPhoneNumberValidation: Rule[] = [
  {
    required: true,
    min: 12,
    message: 'Bitte gib eine valide Rufnummer ein.'
  }
];

export const customStringFieldValidation = (min: number, max: number) => [
  { required: true, message: 'Bitte gib einen Wert an.' },
  {
    min,
    max,
    whitespace: true,
    message: `Der Wert muss min. ${min} und max. ${max} Zeichen lang sein.`
  }
];
