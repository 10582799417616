import React, { useEffect, createContext, useContext, useState, ReactElement } from 'react';
import gql from 'graphql-tag';
import { useLocation } from 'react-router';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { DropResult, DraggableLocation } from 'react-beautiful-dnd';

import {
  BookingActionEventInterface,
  BookingInterface,
  BookingStatus,
  LeadQualifierInterface,
  StatusInterface,
  TrackingInterface
} from '../container/Events';
import { EventMap } from '../components/EventKanban/types';
import { convertToMachineReadable, reorder, reorderQuoteMap } from '../utils/kanbanHelpers';
import { message } from 'antd';
import { EventsMessages, KanbanActionMessages } from '../../config/messages';
import { useAppSelector } from '../../redux/hooks';
import { selectUserData } from '../../UI/redux/userSlice';
import { Role } from '../../UI/utils/roleTypes';
import mutateDeleteApplication from '../../hooks/mutateDeleteApplication';
import mutateDeleteBooking from '../../hooks/mutateDeleteBooking';
import DeclineApplicantModal from '../components/Event/DeclineApplicantModal';
import { getSingleBooking } from '../../Builder/hooks/useSingleBooking';
import EventModal from '../components/Event/EventModal';
import { EVENT_VIEWS } from '../utils/eventViewsTypes';
import { useAllFunnels } from '../../Funnel/redux/funnelSlice';
import KanbanBoard from '../components/EventKanban/KanbanBoard';
import { ColumnsType } from 'antd/lib/table';

export const CHANGE_BOOKING_STATUS = gql`
  mutation($status: ChangeBookingStatus!, $newSortOrder: [ChangeBookingOrder!]!) {
    changeBookingOrder(input: $newSortOrder)

    changeBookingStatus(input: $status) {
      name
      bookingStatus {
        status
        id
      }
    }
  }
`;

export const CREATE_BOOKING_STATUS = gql`
  mutation createBookingStatus($input: CreateBookingStatusInput!) {
    createBookingStatus(input: $input) {
      id
      status
      value
      color
      sortOrder
      updatedAt
    }
  }
`;

const UPDATE_BOOKING_STATUS_ORDER = gql`
  mutation updateBookingStatusOrder($input: [UpdateBookingSortOrderInput!]!) {
    updateBookingStatusOrder(input: $input)
  }
`;

const UPDATE_BOOKING_STATUS = gql`
  mutation updateBookingStatus($input: UpdateBookingStatusInput) {
    updateBookingStatus(input: $input) {
      id
      status
      value
      color
      sortOrder
      updatedAt
    }
  }
`;

export const DELETE_BOOKING_STATUS = gql`
  mutation removeBookingStatus($input: Int!) {
    removeBookingStatus(input: $input)
  }
`;

export const GET_BOOKING_INFO = gql`
  query getBookingInfo($filter: BookingFilterV2!) {
    getBookings(input: $filter) {
      id
      name
      email
      createdAt
      funnelId
      sortOrder
      bookingStatus {
        color
        status
        value
      }
      bookingActionEvent {
        id
        actionEventType
        eventTime
      }
    }
  }
`;

export const GET_ALL_BOOKING_STATUS = gql`
  query getBookingStatus {
    getAllBookingStatus {
      id
      status
      value
      color
      sortOrder
    }
  }
`;

interface ContextBookingType extends BookingInterface {
  leadQualifiers?: LeadQualifierInterface[];
}

interface ContextTypes {
  selectedEvent:
    | {
        event: ContextBookingType;
        index: any;
      }
    | undefined;
  setSelectedEvent: (e: any) => void;
  bookingLoading: boolean;
  columnStatusUpdating: boolean;
  onEventDragEnd: any;
  handleAddNewColumn: (name: string, color: string) => Promise<void>;
  handleDeleteStatusColumn: (bookingStatus: BookingStatus) => Promise<void>;
  handleUpdateStatusColumn: (
    bookingStatus: BookingStatus,
    newColor: string,
    newStatus: string
  ) => Promise<void>;
  loadingCreateBookingStatus: boolean;
  loadingUpdateBookingStatus: boolean;
  loadingDeleteBookingStatus: boolean;
  funnels: { id: number; title: string }[];
  availableStatus: StatusInterface[];
  kanBanState: {
    columns: EventMap;
    ordered: string[];
  };
  refetchBookingInfo: () => void;
  handleDeleteEvent: (id: number, version: string) => void;
  deleteEventLoading: boolean;
  selectedEventLoading: boolean;
  bookings: BookingInterface[];
  setBookings: (bookings: BookingInterface[]) => void;
  changeBookingStatusLocally: (eventId: number, eventStatus: any, event: BookingInterface) => void;
  allBookingStatusLoading: boolean;
  handleUpdateBookingEvents: (
    eventId: number,
    status: string | undefined,
    bookingEvents: BookingActionEventInterface
  ) => void;
}

const initialState: ContextTypes = {
  selectedEvent: undefined,
  setSelectedEvent: () => {},
  bookingLoading: true,
  columnStatusUpdating: false,
  onEventDragEnd: () => {},
  handleAddNewColumn: async () => {},
  handleDeleteStatusColumn: async () => {},
  handleUpdateStatusColumn: async () => {},
  loadingCreateBookingStatus: false,
  loadingUpdateBookingStatus: false,
  loadingDeleteBookingStatus: false,
  funnels: [],
  availableStatus: [],
  kanBanState: {
    columns: {},
    ordered: []
  },
  refetchBookingInfo: () => {},
  handleDeleteEvent: () => {},
  deleteEventLoading: false,
  selectedEventLoading: false,
  bookings: [],
  setBookings: () => {},
  changeBookingStatusLocally: () => {},
  allBookingStatusLoading: false,
  handleUpdateBookingEvents: () => {}
};

const EventsContext = createContext(initialState);

const defaultPage = {
  offset: 0,
  limit: 12
};
function EventsProvider({
  children,
  currentView
}: {
  children: ReactElement;
  currentView: EVENT_VIEWS;
}) {
  const [selectedEvent, setSelectedEvent] = useState<
    | {
        event: ContextBookingType;
        index: any;
      }
    | undefined
  >();

  const location = useLocation() as any;
  const locationFunnelIds =
    location?.state?.funnelIds?.length > 0 ? location?.state?.funnelIds : [];
  const [changeAllBookingStatus] = useMutation(CHANGE_BOOKING_STATUS);
  const [createBookingStatus, { loading: loadingCreateBookingStatus }] = useMutation(
    CREATE_BOOKING_STATUS
  );
  const [updateBookingStatusOrder] = useMutation(UPDATE_BOOKING_STATUS_ORDER);
  const [updateBookingStatus, { loading: loadingUpdateBookingStatus }] = useMutation(
    UPDATE_BOOKING_STATUS
  );
  const [removeBookingStatus, { loading: loadingDeleteBookingStatus }] = useMutation(
    DELETE_BOOKING_STATUS
  );
  const [deleteBooking, { loading: deleteBookingLoading }] = mutateDeleteBooking();
  const [deleteApplication, { loading: deleteApplicationLoading }] = mutateDeleteApplication();
  const userData = useAppSelector(selectUserData);

  const tempColumns = {};

  const [kanBanState, setKanBanState] = useState({
    columns: {},
    ordered: Object.keys(tempColumns)
  });

  const [bookings, setBookings] = useState<BookingInterface[]>([]);

  const [state, setState] = useState({
    bookingFilter: {
      type: 'ALL',
      pagination: {
        currentPageNum: 1,
        booking: {
          offset: defaultPage.offset,
          limit: defaultPage.limit
        },
        tracking: {
          offset: defaultPage.offset,
          limit: defaultPage.limit
        }
      },
      ...{ funnelIds: locationFunnelIds }
    },
    expandedEvent: undefined,
    needlessEvent: undefined,
    initiated: false
  });
  const [declineApplicationId, setDeclineApplicationId] = useState<number | undefined>();

  useEffect(() => {
    if (currentView === EVENT_VIEWS.LIST) return;
    setState({
      ...state,
      bookingFilter: {
        ...state.bookingFilter,
        funnelIds: [...locationFunnelIds]
      }
    });
  }, [locationFunnelIds.length, currentView]);

  const filter = {
    funnelIds: state.bookingFilter.funnelIds,
    type: state.bookingFilter.type,
    includeChoices: false
  };

  const { type: dummyType, ...filterForTracking } = filter;
  const variables = {
    filter: {
      ...filter
    }
  };

  let { loading: bookingLoading, data, refetch: refetchBookingInfoApiCall } = useQuery(
    GET_BOOKING_INFO,
    {
      skip: !filter.funnelIds.length,
      fetchPolicy: 'network-only',
      variables
    }
  );

  const { data: bookingStatus, loading: allBookingStatusLoading } = useQuery(
    GET_ALL_BOOKING_STATUS,
    {
      skip: !location.pathname.includes("kontakte")
    }
  );
  const { getAllBookingStatus: availableStatus } = bookingStatus || { getAllBookingStatus: [] };

  const [selectedEventLoading, setSelectedEventLoading] = useState(false);
  const [eventCache, setEventCache] = useState<{ [key: number]: BookingInterface }>({});

  const handleSetSelectedEvent = async (evnt: any) => {
    if (evnt) {
      const id = evnt.event.id;
      const cachedEvent = eventCache[id];
      if (cachedEvent) {
        setSelectedEvent({ ...evnt, event: cachedEvent });
        return;
      }
      setSelectedEventLoading(true);
      const { data } = await getSingleBooking(id, evnt.event.funnelId);
      const eventData = data.getSingleBooking;
      eventData.leadQualifiers = data.getLeadQualifier;
      setSelectedEvent({ ...evnt, event: eventData });
      setEventCache(prev => ({ ...prev, [id]: eventData }));
      setSelectedEventLoading(false);
    } else {
      setSelectedEvent(evnt);
    }
  };

  const refetchBookingInfo = () => {
    if (filter.funnelIds.length) {
      refetchBookingInfoApiCall();
    }
  };

  const funnels = useAllFunnels();

  const { getBookings: allBookings } = data || {
    getBookings: []
  };
  const [availableColumnStatus, setAvailableColumnStatus] = useState(availableStatus);

  useEffect(() => {
    setAvailableColumnStatus(availableStatus);
  }, [availableStatus.length]);

  useEffect(() => {
    if (!data) return;

    const tempColumns: { [key: string]: BookingInterface[] } = {};
    availableStatus.map((val: StatusInterface, index: number) => {
      tempColumns[val.value] = [];
    });
    allBookings?.map((booking: BookingInterface) => {
      if (booking.bookingStatus)
        tempColumns[booking.bookingStatus.value]?.push &&
          tempColumns[booking.bookingStatus.value].push(booking);
    });
    const sortedData: any = {};
    Object.keys(tempColumns).map(key => {
      const sorted = tempColumns[key].sort((a: BookingInterface, b: BookingInterface) => {
        return a.sortOrder - b.sortOrder;
      });
      sortedData[key] = sorted;
    });

    const sortedStatus = availableStatus
      .sort((a: StatusInterface, b: StatusInterface) => {
        return a.sortOrder - b.sortOrder;
      })
      .map((value: StatusInterface) => {
        return value.value;
      });

    setKanBanState({
      columns: sortedData,
      ordered: sortedStatus
    });
    setEventCache({});
  }, [allBookings, JSON.stringify(availableStatus)]);

  useEffect(() => {
    if (allBookings && allBookings?.length >= 500) message.info(EventsMessages.tooManyBookings, 10);
  }, [allBookings]);

  const changeBookingStatus = async (id: number, value: string, newSortOrder: any[]) => {
    await changeAllBookingStatus({
      variables: {
        status: { value, id },
        newSortOrder
      }
    });

    if (selectedEvent) {
      setSelectedEvent({
        ...selectedEvent,
        event: {
          ...selectedEvent?.event,
          // @ts-ignore
          bookingStatus: { ...selectedEvent?.event?.bookingStatus, value }
        }
      });
    }
  };

  const onEventDragEnd = (result: DropResult, shouldShowEmailPopup: boolean = true) => {
    try {
      if (result.combine) {
        return;
      }

      if (!result.destination) {
        return;
      }

      const source: DraggableLocation = result.source;
      const destination: DraggableLocation = result.destination;

      if (source.droppableId === destination.droppableId && source.index === destination.index) {
        return;
      }
      // In Case of Column Order Change
      if (result.type === 'COLUMN') {
        const ordered: string[] = reorder(kanBanState.ordered, source.index, destination.index);

        const payload = ordered.map((item, index) => ({
          id: availableColumnStatus.find((status: any) => status.value === item)?.id,
          sortOrder: index
        }));

        updateBookingStatusOrder({
          variables: {
            input: payload
          }
        })
          .then(() => {})
          .catch(() => message.error(KanbanActionMessages.columnUpdateError));

        setKanBanState({
          ...kanBanState,
          ordered
        });
        return;
      }

      // @ts-ignore
      const dragEndSource = kanBanState.columns[source.droppableId][source.index];

      const data = reorderQuoteMap({
        quoteMap: kanBanState.columns,
        source,
        destination
      });

      const dragSource = data.quoteMap[source.droppableId].map((value: any, i: number) => {
        return { id: value.id, sortOrder: i };
      });

      const dragDestination = data.quoteMap[destination.droppableId].map(
        (value: any, i: number) => {
          return { id: value.id, sortOrder: i };
        }
      );

      let onDragCombine;
      // If the Ticket is sorted in same Column
      if (source.droppableId === destination.droppableId) {
        onDragCombine = dragSource;
      } else {
        onDragCombine = dragSource.concat(dragDestination);
      }

      if (
        destination.droppableId === 'DECLINE' &&
        dragEndSource?.version !== 'V1' &&
        shouldShowEmailPopup &&
        source.droppableId !== destination.droppableId
      ) {
        setDeclineApplicationId(dragEndSource.id);
      }

      changeBookingStatus(dragEndSource.id, destination.droppableId, onDragCombine);

      setKanBanState({
        ...kanBanState,
        columns: data.quoteMap
      });
    } catch (error) {
      console.log(' error', error);
    }
  };

  const handleUpdateBookingEvents = (
    eventId: number,
    status: string | undefined,
    bookingEvents: BookingActionEventInterface
  ) => {
    if (EVENT_VIEWS.LIST === currentView) {
      setBookings((previousState: BookingInterface[]) => {
        return previousState.map((booking: BookingInterface) => {
          if (booking.id === eventId) {
            return { ...booking, bookingActionEvent: bookingEvents };
          }
          return booking;
        });
      });
    } else if (EVENT_VIEWS.KANBAN === currentView) {
      setKanBanState((previousState: { columns: EventMap; ordered: string[] }) => {
        const newColumns = { ...previousState.columns };
        newColumns[status as string] = newColumns[status as string].map((booking: any) => {
          if (booking.id === eventId) {
            return { ...booking, bookingActionEvent: bookingEvents };
          }
          return booking;
        });

        return { ...previousState, columns: newColumns };
      });
    }
  };

  const handleAddNewColumn = async (newColumn: string, color: string) => {
    const newName = convertToMachineReadable(newColumn);

    const doesAlreadyExist = availableColumnStatus?.find(
      (el: BookingStatus) => el.value === newName
    );

    if (doesAlreadyExist) {
      message.info(`Status "${newColumn}" existiert bereits.`);
      return;
    }

    try {
      const createdBooking = await createBookingStatus({
        variables: {
          input: { status: newColumn, value: newName, color }
        }
      });
      if (createdBooking) {
        const createdBookingStatus = createdBooking.data.createBookingStatus;
        setAvailableColumnStatus([...availableColumnStatus, createdBookingStatus]);
        setKanBanState({
          ordered: [...kanBanState.ordered, newName],
          columns: { ...kanBanState.columns, [newName]: [] }
        });
      }
    } catch (err) {
      // @ts-ignore
      if (err?.message?.toLowerCase()?.includes('already exists')) {
        message.info(`Status "${newColumn}" existiert bereits.`);
        return;
      }
      message.error(KanbanActionMessages.columnAddError);
    }
  };

  const handleDeleteStatusColumn = async ({ id, value }: BookingStatus) => {
    try {
      const deletedBooking = await removeBookingStatus({ variables: { input: id } });
      if (deletedBooking) {
        const deletedColumnStatus = availableColumnStatus.filter((status: any) => status.id !== id);
        setAvailableColumnStatus(deletedColumnStatus);
        setKanBanState((kanBanState: { columns: EventMap; ordered: string[] }) => {
          const ordered = kanBanState.ordered.filter(status => status !== value);
          const columns = { ...kanBanState.columns };
          delete columns[value];
          return {
            ordered: [...ordered],
            columns: { ...columns }
          };
        });
      }
    } catch {
      message.error(KanbanActionMessages.columnDeleteError);
    }
  };

  const [columnStatusUpdating, setColumnStatusUpdating] = useState(false);

  const handleUpdateStatusColumn = async (
    { id, value, status, color, ...props }: BookingStatus,
    newColor: string,
    newStatus: string
  ) => {
    setColumnStatusUpdating(true);
    try {
      const updatedBooking = await updateBookingStatus({
        variables: {
          input: {
            id,
            ...(newStatus && { status: newStatus }),
            ...(newColor && { color: newColor })
          }
        }
      });

      const updateBookingRes = updatedBooking.data.updateBookingStatus;
      setAvailableColumnStatus((prevStatus: any) =>
        prevStatus.map((status: any) => {
          return status.id === id ? updateBookingRes : status;
        })
      );
      setColumnStatusUpdating(false);
    } catch {
      message.error(KanbanActionMessages.columnUpdateError);
    }
  };

  const getFilteredColumns = (id: any) => {
    const updatedColumns = Object.keys(kanBanState.columns).reduce((acc, key) => {
      (acc as any)[key] =
        key === selectedEvent?.event?.bookingStatus?.value
          ? (kanBanState.columns as any)[key].filter((booking: any) => booking.id !== id)
          : (kanBanState.columns as any)[key];
      return acc;
    }, {} as typeof kanBanState.columns);

    return updatedColumns;
  };

  const handleDeleteEvent = (id: number, version: string) => {
    return new Promise((resolve, reject) => {
      if (version === 'V1')
        deleteBooking({ variables: { bookingId: id } })
          .then((res: any) => {
            if (res.data.deleteBooking) {
              message.success(EventsMessages.deleteBookingSuccess);
              refetchBookingInfo();
              setSelectedEvent(undefined);
              resolve(id);
            } else {
              reject();
            }
          })
          .catch(() => {
            message.error(
              userData.role === Role.AGENCY_CUSTOMER
                ? EventsMessages.deleteBookingNotPermitted
                : EventsMessages.deleteBookingError
            );
            reject();
          });
      else
        return deleteApplication({ variables: { bookingId: id } })
          .then((res: any) => {
            if (res.data.deleteApplication) {
              message.success(EventsMessages.deleteBookingSuccess);
              setKanBanState(previousState => ({
                ...previousState,
                columns: getFilteredColumns(selectedEvent?.event?.id)
              }));
              setBookings((previousState: BookingInterface[]) => {
                return previousState.filter((booking: BookingInterface) => booking.id !== id);
              })
              setSelectedEvent(undefined);
              resolve(id);
            } else {
              reject();
              throw new Error();
            }
          })
          .catch(() => {
            message.error(
              userData.role === Role.AGENCY_CUSTOMER
                ? EventsMessages.deleteBookingNotPermitted
                : EventsMessages.deleteBookingError
            );
            reject();
          });
    });
  };

  const changeBookingStatusLocally = (
    eventId: number,
    eventStatus: any,
    event: BookingInterface
  ) => {
    const { action, ...rest } = eventStatus;
    setEventCache((previousState: any) => {
      return {
        ...previousState,
        [eventId]: {
          ...previousState[eventId],
          bookingStatus: {
            id: (previousState[eventId] as any).bookingStatus.id,
            status: action,
            ...rest
          }
        }
      };
    });
    if (EVENT_VIEWS.KANBAN === currentView) {
      const sourceEvent = event?.bookingStatus?.value;
      // @ts-ignore
      const sourceIndex = kanBanState.columns[sourceEvent].findIndex((_: BookingInterface) => {
        return _.id === eventId;
      });
      onEventDragEnd(
        {
          type: 'EVENT',
          combine: null,
          destination: { droppableId: eventStatus.value, index: 0 },
          draggableId: `${eventId}`,
          mode: 'FLUID',
          reason: 'DROP',
          source: {
            index: sourceIndex,
            droppableId: `${selectedEvent?.event.bookingStatus?.value}`
          }
        },
        false
      );
    } else if (EVENT_VIEWS.LIST === currentView) {

      setSelectedEvent((previousState: any) => {
        return {
          ...previousState,
          event: {
            ...previousState?.event,
            bookingStatus: {
              id: (previousState?.event as any).bookingStatus.id,
              status: action,
              ...rest
            }
          }
        };
      });

      setBookings((previousState: BookingInterface[]) => {
        return previousState.map((booking: BookingInterface) => {
          if (booking.id === eventId) {
            return {
              ...booking,
              bookingStatus: { id: (booking as any).bookingStatus.id, status: action, ...rest }
            };
          }
          return booking;
        });
      });
    }
  };

  const deleteEventLoading = deleteBookingLoading || deleteApplicationLoading;
  return (
    <EventsContext.Provider
      value={{
        selectedEvent,
        setSelectedEvent: handleSetSelectedEvent,
        bookingLoading,
        columnStatusUpdating,
        onEventDragEnd,
        handleAddNewColumn,
        handleDeleteStatusColumn,
        handleUpdateStatusColumn,
        loadingCreateBookingStatus,
        loadingUpdateBookingStatus,
        loadingDeleteBookingStatus,
        funnels,
        availableStatus: availableColumnStatus,
        refetchBookingInfo,
        kanBanState,
        handleDeleteEvent,
        deleteEventLoading,
        selectedEventLoading,
        bookings,
        setBookings,
        changeBookingStatusLocally,
        allBookingStatusLoading,
        handleUpdateBookingEvents
      }}
    >
      {children}
      <EventModal
        leadQualifier={selectedEvent?.event?.leadQualifiers || []}
        eventType={'booking'}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        changeBookingStatusLocally={changeBookingStatusLocally}
        handleDelete={handleDeleteEvent}
        deleteLoading={deleteEventLoading}
        eventLoading={selectedEventLoading}
      />
      <DeclineApplicantModal
        visible={!!declineApplicationId}
        setVisibility={() => {
          setDeclineApplicationId(undefined);
        }}
        eventId={declineApplicationId as number}
      />
    </EventsContext.Provider>
  );
}

const useEventsContext = () => useContext(EventsContext);

export { EventsProvider, useEventsContext };
