import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { getEnvironment } from '../../helper/environment';
import { getAuthData, startTokenExpirationCheck } from '../container/Login';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { LoginMessages } from '../../config/messages';
import { message } from 'antd';

export const getAppoloClient = () => {
  startTokenExpirationCheck(window.handleLogout);

  let graphQlLink = createUploadLink({
    uri: getEnvironment().graphqlEndpoint,
    headers: {
      'keep-alive': 'true'
    }
  }) as any;

  const authLink = setContext((_, { headers }) => {
    const token = getAuthData('token');
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message: errorMessage, extensions }) => {
        console.log(graphQLErrors, errorMessage);

        if (
          errorMessage === '401: Unauthorized' ||
          (extensions && extensions['code'] && extensions['code'] === 'UNAUTHENTICATED')
        ) {
          message.destroy();
          message.warning(LoginMessages.automaticLogout, 8);
          window.handleLogout();
        }
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });
  let allLinks = errorLink.concat(authLink);
  allLinks = allLinks.concat(graphQlLink);
  return new ApolloClient({
    link: allLinks,
    cache: new InMemoryCache({
      addTypename: false
    })
  });
};

export const getSessionStorageData = (key: string): any => {
  const sessionStorageData = sessionStorage.getItem(key);
  return JSON.parse(sessionStorageData as string);
};

export const setSessionStorageData = (key: string, data: any): void => {
  sessionStorage.setItem(key, JSON.stringify(data));
};


const sanitizeString = (value: string): string => {
  return value
    .replace(/[^a-zA-Z0-9-_"'%]/g, '')
    .replace(/%/g, ' ')
    .trim();
}

export const getAndValidateParams = () => {
  const params = new URLSearchParams(window.location.search);
  const sanitizedParams: { [key: string]: string } = {};

  params.forEach((value, key) => {
    const sanitizedKey = sanitizeString(key);
    const sanitizedValue = sanitizeString(value);
    sanitizedParams[sanitizedKey] = sanitizedValue;
  });

  return sanitizedParams;
}

