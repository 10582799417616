import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import {
  IconProvider as Icon
  // @ts-ignore
} from 'meetovo-frontend-booking';
import { FaPlus } from 'react-icons/fa';
import { Card, Col, Row } from 'antd';
import BuilderSettingsTitle from '../../sharedUI/BuilderSettingsTitle';
import { ActionsController } from '../../sharedUI/ActionsController';
import TextAlignmentButtons from '../../../../SharedUI/components/TextAlignmentButtons';
import ImageWithoutCacheOnError from '../../sharedUI/ImageWithoutCacheOnError';
import { defaultImage } from '../../../config/builderUi';
import ImageUploadV3 from '../../../../GeneralComponents/ImageUploadV3';
import { SortableContainer } from 'react-sortable-hoc';
import LinkCard from './LinkCard';
import RichTextComponent from '../RichTextComponent/container/RichTextComponent';
import { defaultName } from '../../../config/supportCardDefaultValues';
import { CraftElementBaseProps, getRandomId } from '../../../helper/craftJs';
import { CRAFT_ELEMENTS } from '../../../config/craftElements';
import { useCurrentlyEditingBuilderTheme } from '../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';

interface Props extends CraftElementBaseProps {
  children?: React.ReactElement;
  contentAlign?: any;
  onlySettingsReq?: boolean;
  imageSrc?: string;
  contactLinkIcons?: {
    name: string;
    link: string;
    icon: string;
    iconColor?: string;
  }[];
}

export const ContactComponent = ({
  children,
  contentAlign,
  onlySettingsReq,
  imageSrc,
  contactLinkIcons
}: Props) => {
  const theme = useCurrentlyEditingBuilderTheme();

  const { query } = useEditor();

  useEffect(() => {
    if (!query.node(nodeId).get()) return;
  }, []);

  const { nodeId, currentNode } = useNode(node => {
    return {
      currentNode: node,
      nodeId: node.id
    };
  });

  const { actions } = useEditor();

  useEffect(() => {
    if (currentNode.data.nodes.length === 0) {
      const nodeTreeTitleComponent = query
        .parseReactElement(
          <Element
            //@ts-ignore
            fontSize={3}
            align="left"
            id={getRandomId(10)}
            is={RichTextComponent}
            rawState={JSON.parse(defaultName)}
            showPlusIcon={false}
            onlySettingsReq
            required
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTreeTitleComponent, nodeId);
    }
  }, []);

  const nestedChildren = children?.props?.children;

  return (
    <ActionsController
      onlySettingsReq={onlySettingsReq}
      className={`contact-component__wrapper`}
      style={{ justifyContent: contentAlign }}
    >
      <div className="img-element__wrapper">
        <ImageWithoutCacheOnError className={`img-rounded`} src={imageSrc || defaultImage} />
      </div>
      <div className="d-flex flex-column remove-margin-from-rich-text">
        {nestedChildren?.[0]}
        <div className="d-flex mt-1">
          {contactLinkIcons?.map((contactLink, index) => {
            return (
              <Icon
                key={index}
                name={contactLink?.icon}
                style={{
                  color: contactLink?.iconColor || theme?.accentColor,
                  fontSize: 20,
                  marginRight: 10
                }}
              />
            );
          })}
        </div>
      </div>
    </ActionsController>
  );
};

export const ContactComponentDefaultProps = {
  boldText: 'bold',
  italicText: 'italic',
  underlineText: 'underline',
  contentAlign: 'left',
  title: 'Ich will mehr erfahren',
  actionLogic: 'next-page',
  customClasses: '',
  name: 'Full Name',
  contactLinkIcons: [
    {
      name: 'Icon 1',
      url: 'https://mail.google.com/',
      icon: 'FaCalendarAlt',
      iconColor: ''
    },
    {
      name: 'Icon 2',
      url: 'https://mail.google.com/',
      icon: 'FiPhoneCall',
      iconColor: ''
    },
    {
      name: 'Icon 3',
      url: 'https://mail.google.com/',
      icon: 'FaInstagram',
      iconColor: ''
    }
  ]
};

const SortableListContainer = SortableContainer(({ children, ...rest }: any) => {
  return (
    <div className="sortable-container" {...rest}>
      {children}
    </div>
  );
});

const ContactComponentSettings = () => {
  const {
    actions: { setProp },
    nodeId,
    props
  } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const { actions } = useEditor();
  const [sortStarted, setSortStarted] = useState(false);

  const updateContactLinkIcons = (newArray: any[]) => {
    setProp((_: any) => (_.contactLinkIcons = newArray));
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setSortStarted(false);
    if (oldIndex === newIndex) return;
    const array = [...props.contactLinkIcons];
    const elementToMove = array.splice(oldIndex, 1)[0];
    array.splice(newIndex, 0, elementToMove);
    setProp((props: any) => {
      props.contactLinkIcons = array;
    });
  };

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Support-Karte" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={11}>
            <BuilderSettingsTitle title="Ausrichtung" />
            <TextAlignmentButtons
              align={props.contentAlign}
              onChange={align => {
                actions.setProp(nodeId, (props: any) => (props.contentAlign = align));
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Hochladen" />
            <ImageUploadV3
              previewImage={props.imageSrc}
              onChange={(url: string, form: any) => {
                setProp((props: any) => {
                  props.imageSrc = url || '';
                });
              }}
              showRatioSelector={false}
              minWidth={200}
              minHeight={150}
              maxWidth={800}
              maxHeight={600}
              uploadedImage
              grid
              minZoom={0}
              restrictPosition={false}
              existingImageURL={''}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row sortable-link-card">
          <Col span={24}>
            <BuilderSettingsTitle title="Kontakt Links" />
            <SortableListContainer
              id="carousel-slides-listing"
              className="sortable-container"
              onSortEnd={onSortEnd}
              useDragHandle
              pressDelay={1}
              onSortStart={() => setSortStarted(true)}
            >
              {props.contactLinkIcons?.map((contactLink: any, index: number) => {
                return (
                  <LinkCard
                    key={'index' + index}
                    contactLink={contactLink}
                    nodeId={nodeId}
                    props={props}
                    index={index}
                    idx={index}
                    iconsLinks={props.contactLinkIcons}
                    sortStarted={sortStarted}
                  />
                );
              })}
            </SortableListContainer>

            {props?.contactLinkIcons?.length < 10 && (
              <Row align="middle" justify="center" className="mt-4">
                <Col sm={24}>
                  <div
                    className="add-button-green circular-plus-button container__add-wrapper-icon append-container-icon"
                    onClick={() => {
                      updateContactLinkIcons([
                        ...props.contactLinkIcons,
                        {
                          name: `Icon ${props?.contactLinkIcons?.length + 1}`,
                          url: '',
                          icon: '',
                          iconColor: ''
                        }
                      ]);
                    }}
                  >
                    <FaPlus className="container-plus-icon" />
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

ContactComponent.craft = {
  name: CRAFT_ELEMENTS.CONTACT,
  props: ContactComponentDefaultProps,
  related: {
    settings: ContactComponentSettings
  },
  rules: {
    canMoveIn: () => false,
    canMoveOut: () => false
  }
};
