import React, { useState, useEffect } from 'react';
import { message, Alert, Checkbox } from 'antd';
import { getValueByPropString } from '../../helper/objectAccess';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { LoginMessages } from '../../config/messages';
import { getEnvironment } from '../../helper/environment';
import LoginSelector from '../components/LoginSelector/LoginSelector';
import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons';
import AlternativeEmailInput from '../components/AlternativeEmailInput';
import { getUrlParam } from '../../helper/url';
import { getCookie, setCookie } from '../../helper/cookies';
import Analytics from 'analytics';
import mixpanel from 'mixpanel-browser';
//@ts-ignore
import googleTagManager from '@analytics/google-tag-manager';
import ReactPixel from 'react-facebook-pixel';
import LoginQuote from '../components/LoginQuote';
import EmailLoginAndRegistration from '../components/EmailLoginAndRegistration';
import GoogleConnector from '../components/GoogleConnector';
import { isFacebookInApp } from '../../VoiceRecorder/helper/deviceHelper';
import { useLocation, useNavigate } from 'react-router';
import { logoURL } from '../../UI/utils/imageConstants';
import { mixpanelEvents } from '../../UI/utils/mixpanel';
import { LocalStorageKeys } from '../../Share/constants/localstorageKeys';
import LinkedInTag from 'react-linkedin-insight';
import { getAndValidateParams, getSessionStorageData, setSessionStorageData } from '../helper/login';
import { SessionStorageKeys } from '../../Share/constants/sessionStorageKeys';

export const LOGIN = gql`
  mutation login($input: LoginProperties!) {
    login(input: $input) {
      expirationDate
      token
      activated
      lastLogin
      isAdmin
      alternativeEmailEnabled
      alternativeEmail
      provider
      connectedToGoogle
      isEmployee
    }
  }
`;

interface LoginProps {
  onLogin(): any;
}

interface UserData {
  id: number;
  email: string;
  firstName: string;
  imageURL: string;
  lastName: string;
  questionsAnswered: Array<{
    question: string;
    answer: string;
  }>;
}

var loginCheckInterval: any;

export function getAuthData(propString?: string) {
  if (getMeetovoAuthData())
    return getValueByPropString(JSON.parse(getMeetovoAuthData()), propString);
}

export function closeSession() {
  clearInterval(loginCheckInterval);
  localStorage.removeItem(LocalStorageKeys.MEETOVO_AUTH_DATA);
  localStorage.removeItem(LocalStorageKeys.MEETOVO_FUNNEL_OWNER);
  sessionStorage.removeItem(LocalStorageKeys.MEETOVO_AUTH_DATA);
  localStorage.removeItem(LocalStorageKeys.MEETOVO_CHECKOUT_PACKAGE_DETAILS);
  localStorage.removeItem(LocalStorageKeys.MEETOVO_CHECKOUT_USER_INFO);
  localStorage.removeItem(LocalStorageKeys.MEETOVO_INACTIVE_MODAL_VISIBLE);
}

export function isTokenValid() {
  let valid = false;
  let expirationDate = getAuthData('expirationDate');
  const token = getAuthData('token');

  valid = !!token && moment(expirationDate).isAfter(moment());

  return valid;
}

export function startTokenExpirationCheck(onInvalid?: any) {
  if (!loginCheckInterval) {
    loginCheckInterval = setInterval(() => {
      if (!isTokenValid()) {
        onInvalid && onInvalid();
        closeSession();
      }
    }, 10000);
  }
}

export const populateLocalStorageWithAuth = (props: LocalStorageAuthProps) => {
  sessionStorage.setItem(LocalStorageKeys.MEETOVO_AUTH_DATA, JSON.stringify(props));
  localStorage.setItem(LocalStorageKeys.MEETOVO_AUTH_DATA, JSON.stringify(props));
};

export const getMeetovoAuthData = (): any =>
  sessionStorage.getItem(LocalStorageKeys.MEETOVO_AUTH_DATA) ||
  localStorage.getItem(LocalStorageKeys.MEETOVO_AUTH_DATA);

interface LocalStorageAuthProps {
  expirationDate: Date;
  token: string;
  activated: boolean;
  lastLogin: Date;
  isEmployee: boolean;
  isAdmin: boolean;
}

let analytics: any;

function Login({ onLogin }: LoginProps) {
  const isDev = getEnvironment().isDev;
  const [getMeetovoToken, { loading }] = useMutation(LOGIN);
  const [errorMessage, setErrorMessage] = useState<{ description: string; message: string }>({
    description: '',
    message: ''
  });
  const [showLoginSelector, setShowLoginSelector] = useState(false);
  const [legalAgreement, setLegalAgreement] = useState(false);
  const [showAlternativeEmailAdressInput, setShowAlternativeEmailAdressInput] = useState(false);
  const { pathname } = useLocation();
  const isRegister = pathname.includes('/registrieren');
  const [loginMode, setLoginMode] = useState(isRegister ? 'register' : 'login');
  const partnerId = getUrlParam('pid') || getCookie('meetovoPartnerId');
  const source = getCookie('meetovoFirstUserReferrer');
  const showLoginText =
    (loginMode === 'login' || loginMode === 'email-login') && !showAlternativeEmailAdressInput;
  const showRegisterText =
    (loginMode === 'register' || loginMode === 'email-register') &&
    !showAlternativeEmailAdressInput;
  const showGoogleLoginButton =
    !errorMessage.message && !showAlternativeEmailAdressInput && !isFacebookInApp();
  const passwordResetToken = getUrlParam('passwordResetToken');

  const navigate = useNavigate();

  if (partnerId !== getCookie('meetovoPartnerId')) setCookie('meetovoPartnerId', partnerId, 7);

  useEffect(() => {
    if (!isDev) {
      analytics = Analytics({
        app: 'app.meetovo.de',
        plugins: [
          googleTagManager({
            containerId: 'GTM-M53NLW4'
          })
        ]
      });

      analytics.page();

      if (isRegister) {
        LinkedInTag.init('3881489', 'app.meetovo.de', false);
        LinkedInTag.track('15797009');
        ReactPixel.track('ViewRegistration', {});
        analytics.track('view_registration');
      }
    }

    if (passwordResetToken) setLoginMode('email-login');
  }, []);

  useEffect(() => {
    const UTMParams = getAndValidateParams()
    if (Object.keys(UTMParams).length && !getSessionStorageData(SessionStorageKeys.MEETOVO_UTM_PARAMETERS)) {
        setSessionStorageData(SessionStorageKeys.MEETOVO_UTM_PARAMETERS, UTMParams);
    }
  }, []);

  const handleAfterLogin = (loginData: any) => {
    const {
      expirationDate,
      token,
      activated,
      lastLogin,
      isAdmin,
      alternativeEmailEnabled,
      provider,
      connectedToGoogle,
      isEmployee
    } = loginData;

    if (provider === 'google' && !connectedToGoogle) {
      setErrorMessage({
        message: 'Autorisierung fehlgeschlagen',
        description: LoginMessages.hasNoRefreshTokenError
      });
      return;
    }

    if (!activated) {
      setErrorMessage({
        message: 'Account deaktiviert',
        description: LoginMessages.accountDisabledMEssage
      });
      return;
    }
    mixpanel.track(loginMode);
    populateLocalStorageWithAuth({
      expirationDate,
      token,
      activated,
      lastLogin,
      isEmployee,
      isAdmin
    });

    if (isAdmin) {
      setShowLoginSelector(true);
    } else {
      if (provider != 'google' || alternativeEmailEnabled != null) onLogin();
      else setShowAlternativeEmailAdressInput(true);

      if (!isDev) {
        if (!lastLogin) {
          LinkedInTag.track('15797017');
          if (analytics) analytics.track('sign_up');
          ReactPixel.track('StartTrial', {});
        } else {
          if (analytics) analytics.track('login');
          ReactPixel.track('Login', {});
        }
      }
    }
  };

  function setAuthData(data: any) {
    getMeetovoToken({
      variables: { input: { code: data.code, activated: true, partnerId, source } }
    })
      .then(res => {
        handleAfterLogin(res.data.login);
      })
      .catch(e => {
        if (e.message.includes('User is already registered via E-Mail'))
          message.error(LoginMessages.couldntLoginToGoogleAccountalreadyRegisteredViaEmail, 8);
        else showGeneralLoginError();
      });
  }

  function setAuthDataByAdmin(coachId: number) {
    getMeetovoToken({ variables: { input: { coachId } } })
      .then(res => {
        const { expirationDate, token, activated } = res.data.login;

        const currentAuthData = JSON.parse(getMeetovoAuthData() || '{}');

        populateLocalStorageWithAuth({
          ...(currentAuthData || {}),
          expirationDate,
          token,
          activated
        });

        onLogin();

        setShowLoginSelector(false);
      })
      .catch(e => {
        console.log(e);

        message.error(LoginMessages.loginByAdminError);
      });
  }

  function showGeneralLoginError() {
    message.error(LoginMessages.generalLoginError);
  }

  function toggleLoginMode(e: any) {
    e.preventDefault();
    const { search } = location;
    navigate(
      loginMode === 'register' || loginMode === 'email-register'
        ? '/login' + (search || '')
        : '/registrieren' + (search || '')
    );
    setLoginMode(loginMode === 'register' ? 'login' : 'register');
  }

  function validateLegalCheckbox() {
    return legalAgreement || loginMode === 'login';
  }

  return (
    <div className="page login">
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
          #root, .app, .ant-layout {
            background: initial !important;
        }

       @font-face {
        font-family: Linearicons;
        src: url(${process.env.PUBLIC_URL}/fonts/Linearicons.eot?n1z373);
        src: url(${process.env.PUBLIC_URL}/fonts/Linearicons.eot?#iefixn1z373) format("embedded-opentype"), url(${process.env.PUBLIC_URL}/fonts/Linearicons.woff?n1z373) format("woff"), url(${process.env.PUBLIC_URL}/fonts/Linearicons.ttf?n1z373) format("truetype"), url(${process.env.PUBLIC_URL}/fonts/Linearicons.svg?n1z373#lg) format("svg");
        font-weight: 400;
        font-style: normal
      }

      body {
        background: url(${process.env.PUBLIC_URL}/login/dashboard-bg.jpg) !important;
        background-position: left top !important;
        background-size: cover !important;
      }
      
      @media (max-width:991.98px) {
        body {
          background: url(${process.env.PUBLIC_URL}/login/dashboard-bg-mobil.jpg) !important;
          background-position: left top !important;
          background-size: 100% !important;
        }
      }
    `
        }}
      />

      <section className="section section-layout-3-outer">
        <div className="section-layout-3">
          <div className="layout-2 section-layout-3-header">
            <div className="layout-2-inner">
              <div className="layout-2-item">
                {loginMode === 'login' ||
                  (loginMode === 'email-login' && (
                    <a className="link link-icon link-icon-left" href="https://meetovo.de">
                      <ArrowLeftOutlined />
                      <span>Startseite</span>
                    </a>
                  ))}
              </div>
              <div className="layout-2-item">
                <p className="text-1">
                  <span>
                    {loginMode === 'login' || loginMode === 'email-login'
                      ? 'Hast du noch keinen Account?'
                      : 'Hast du bereits einen Account?'}
                  </span>
                  <a href="#" onClick={toggleLoginMode}>
                    {' '}
                    {loginMode === 'login' || loginMode === 'email-login'
                      ? 'Registrieren'
                      : 'Login'}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="section-layout-3-main">
            <div className="section-1">
              <div className="container">
                <img className="logo" src={logoURL} />
                <div className="box-shadow-1">
                  {showLoginText && <h3>Willkommen zurück!</h3>}

                  {showRegisterText && <h3>Richte deinen Funnel noch heute ein!</h3>}

                  {showRegisterText && (
                    <ul className="pt-classic-list">
                      <li>Keine Zahlungsdetails</li>
                      <li>Testzeitraum endet automatisch</li>
                      <li>Du erhältst alles von uns, um durchzustarten</li>
                    </ul>
                  )}

                  {(loginMode === 'email-login' || loginMode == 'email-register') &&
                    !showAlternativeEmailAdressInput && (
                      <EmailLoginAndRegistration
                        afterLogin={handleAfterLogin}
                        validator={validateLegalCheckbox}
                        validationFailedMessage={LoginMessages.checkboxNotChecked}
                        mode={loginMode}
                        disableModeSwitch
                        hidePolicyCheckbox
                      />
                    )}

                  {showGoogleLoginButton &&
                    loginMode != 'email-login' &&
                    loginMode != 'email-register' ? (
                    <GoogleConnector
                      onSuccess={authData => setAuthData(authData)}
                      onFailure={() => showGeneralLoginError()}
                      validator={validateLegalCheckbox}
                      validationFailedMessage={LoginMessages.checkboxNotChecked}
                      trigger={
                        <a className="border-button gray google-g">
                          {loading ? 'Wird geladen...' : 'Jetzt mit Google einloggen'}
                        </a>
                      }
                    />
                  ) : null}

                  {errorMessage.message && (
                    <Alert
                      message={errorMessage.message}
                      description={errorMessage.description}
                      type="error"
                      showIcon
                    />
                  )}

                  {loginMode != 'email-login' &&
                    loginMode != 'email-register' &&
                    !showAlternativeEmailAdressInput && (
                      <a
                        className="border-button gray"
                        onClick={() =>
                          setLoginMode(loginMode === 'login' ? 'email-login' : 'email-register')
                        }
                        style={{ marginBottom: '0.75em' }}
                      >
                        <MailOutlined />
                        Jetzt mit E-Mail einloggen
                      </a>
                    )}

                  {!showAlternativeEmailAdressInput && showRegisterText && (
                    <Checkbox
                      className="login__legal-checkbox"
                      value={legalAgreement}
                      onChange={() => setLegalAgreement(!legalAgreement)}
                    >
                      Ich akzeptiere die{' '}
                      <a
                        href="https://meetovo-file-bucket.s3.eu-central-1.amazonaws.com/vertraege/MEETOVO-Nutzungsbedingungen-V3.pdf"
                        target="_blank"
                      >
                        Nutzungsbedingungen
                      </a>{' '}
                      und die{' '}
                      <a href="https://www.meetovo.de/datenschutz" target="_blank">
                        Datenschutzerklärung
                      </a>
                      .
                    </Checkbox>
                  )}

                  {showRegisterText && <LoginQuote />}

                  {showAlternativeEmailAdressInput && <AlternativeEmailInput onSave={onLogin} />}
                  {showLoginSelector && <LoginSelector setAuthDataByAdmin={setAuthDataByAdmin} />}
                </div>

                {loginMode === 'register' ? (
                  <ul className="horizontal-list-of-logos custom-trust-badges">
                    <li>
                      <img src={process.env.PUBLIC_URL + '/login/badges/siegel-dsgvo.png'} />
                    </li>
                    <li>
                      <img
                        src={process.env.PUBLIC_URL + '/login/badges/siegel-hosted-in-germany.png'}
                      />
                    </li>
                    <li>
                      <img
                        src={process.env.PUBLIC_URL + '/login/badges/siegel-ssl-encryption.png'}
                      />
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
